import { render, staticRenderFns } from "./ledger_approve_pc.vue?vue&type=template&id=249c8ba0&scoped=true"
import script from "./ledger_approve_pc.vue?vue&type=script&lang=js"
export * from "./ledger_approve_pc.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "249c8ba0",
  null
  
)

export default component.exports