<template>
	<ledger-list scene="approve"></ledger-list>
</template>

<script>
	import ledgerList from './ledger_list_pc.vue'
	
	export default {
		name: 'ledger_approve',
		
		components: {
			ledgerList
		}
	};
</script>

<style lang="stylus" scoped>
</style>